import "core-js/modules/es.array.push.js";
import { mapGetters } from 'vuex';
// import Cookies from 'js-cookie';
import Breadcrumb from '@/components/Breadcrumb/index.vue';
// import Hamburger from '@/components/Hamburger/index.vue';
import { setup } from '@/i18n/index';
import { TIMEZONES } from '@/utils/timezone';
export default {
  components: {
    Breadcrumb
    // Hamburger,
  },

  data() {
    return {
      timezones: TIMEZONES
    };
  },
  computed: {
    ...mapGetters(['sidebar', 'name', 'en_name', 'timezone', 'unreadCount'])
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    async logout() {
      await this.$store.dispatch('user/logout');
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      setup(lang);
    },
    changeTimezone(idx) {
      this.$store.dispatch('settings/changeSetting', {
        key: 'timezone',
        value: idx
      });
      window.location.reload();
    },
    checkMessage() {
      if (!this.$route.path.startsWith('/message')) {
        this.$router.push('message');
      }
    }
  }
};