/* eslint-disable global-require */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ElementUILocale from 'element-ui/lib/locale';
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
Vue.use(VueI18n);
const DEFAULT_LANG = 'zh';
const LOCALE_KEY = 'DemoLanguage';
const locales = {
  zh: require('./zh.json'),
  en: require('./en.json')
};
if (process.env.VUE_APP_TITLE != null) {
  locales.zh.title = process.env.VUE_APP_TITLE;
}
const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages: locales,
  silentTranslationWarn: true
});
const UIlocales = {
  zh: zhLocale,
  en: enLocale
};
const setUIlocales = lang => {
  switch (lang) {
    case 'zh':
      return UIlocales.zh;
    default:
      return UIlocales.en;
  }
};
export const setup = lang => {
  if (lang === undefined) {
    lang = window.localStorage.getItem(LOCALE_KEY);
    if (locales[lang] === undefined) {
      lang = DEFAULT_LANG;
    }
  }
  window.localStorage.setItem(LOCALE_KEY, lang);
  Object.keys(locales).forEach(item => {
    document.body.classList.remove(`lang-${item}`);
  });
  document.body.classList.add(`lang-${lang}`);
  document.body.setAttribute('lang', lang);
  Vue.config.lang = lang;
  i18n.locale = lang;
  ElementUILocale.use(setUIlocales(lang));
};
setup();
export default i18n;