import "core-js/modules/es.array.push.js";
import { asyncRoutes, constantRoutes } from '@/router';

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(permissions, route) {
  let result = true;
  if (route.meta && route.meta.permissions) {
    result = permissions.some(permission => route.meta.permissions.includes(permission.code));
  }
  return result;
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param permissions
 */
export function filterAsyncRoutes(routes, permissions) {
  const res = [];
  routes.forEach(route => {
    const tmp = {
      ...route
    };
    if (hasPermission(permissions, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, permissions);
      }
      res.push(tmp);
    }
  });
  return res;
}
const state = {
  routes: [],
  addRoutes: []
};
const mutations = {
  SET_ROUTES: (State, routes) => {
    State.addRoutes = routes;
    State.routes = constantRoutes.concat(routes);
  }
};
const actions = {
  generateRoutes({
    commit
  }, permissions) {
    return new Promise(resolve => {
      const accessedRoutes = filterAsyncRoutes(asyncRoutes, permissions);
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};